<template>
  <v-card flat class="px-4">
    <v-card-text>
      <!-- #### Tarifbänder #### -->
      <v-row style="overflow-x: auto; overflow-y: hidden;">
        <v-col class="mb-4">
          <!-- <span class="py-2 text-h6">{{ $t('condAdmin.additionalProduct.tariffLines') }}</span> -->
          <table class="text-center additionalProductTable v-data-table" cellspacing=0 cellpadding=0>
            <!--
              <tr>
                <td class="border"></td>
                <th
                  class="pa-2 firstRow"
                  v-for="(td) in params.tariffDescription"
                  :key="td.index"
                >
                </th>
              </tr>-->

            <tr>
              <td class="text-center first firstRow px-2">{{ $t('condAdmin.additionalProduct.tariffSubName') }}</td>
              <td class="px-4 firstRow" v-for="(td, index) in params.tariffDescription" :key="index">
                <span v-if="readonly == true">{{ td.tariffName }}</span>
                <EditField v-if="readonly !== true" @change="setTariffName(index, $event)" :value="td.tariffName"
                  clearable :readonly="readonly" />
              </td>
            </tr>
            <tr>
              <td class="text-center first px-2">{{ $t('condAdmin.additionalProduct.tariffId') }}</td>
              <td class="px-4" v-for="(td, index) in params.tariffDescription" :key="index">
                <span v-if="readonly == true"><strong>{{ td.tariffId }}</strong></span>
                <EditField v-if="readonly !== true" @change="setTariffId(index, $event)" :value="td.tariffId" clearable
                  :readonly="readonly" type="int" />

              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <!-- Provision Anfang -->
      <!-- derzeit noch nicht verlangt
        <v-row style="overflow-x: auto; overflow-y: hidden;">
          <v-col class="mb-4">
            <span class="py-2 text-h6">{{texts.de.commission }}</span>
            <table
              class="text-center additionalProductTable v-data-table"
              cellspacing=0
              cellpadding=0
            >
            -->
      <!-- Sparten -->
      <!--
            <tr>
              <td class="text-center firstRow first px-2">{{ $t('condAdmin.additionalProduct.tariffSubName') }}</td>
              <td
                  class="firstRow px-4"
                 v-for="(sparte) in params.tariffSparten"
                :key="sparte">
                <span >{{ sparte }}</span>
              </td>
            </tr>
            -->
      <!-- Zahlweise -->
      <!--
             <tr>
              <td class="text-center first  px-4">{{ $t('base.paymentMode.label') }}</td>
              <td
                  class="px-4"
                   v-for="(sparte) in params.tariffSparten"
                  :key="sparte"
                >
                <span v-if="readonly === true">
                      {{ $t('base.paymentMode.' +  (params.tariffCommission)[sparte.toUpperCase()].paymentMode) }}</span>

                <v-select
                    v-if="readonly !== true"
                    v-model="(tmpParams.tariffCommission)[sparte.toUpperCase()].paymentMode"
                    :items="paymentModes"
                    item-text="text"
                    item-value="value"

                    @change="setPaymentMode(sparte,$event)"
                  ></v-select>
              </td>
            </tr>
             <tr>
              <td class="text-center first  px-4">{{ $t('condAdmin.additionalProduct.commissionValue') }}</td>
              <td
                  class="px-4 "
                   v-for="(sparte) in params.tariffSparten"
                  :key="sparte"
                >

                <span v-if="readonly === true">
                      {{ $t(  $filters.formatNumber((params.tariffCommission, null, NULLVALUE)[sparte.toUpperCase()].value)) + ''+getSuffix((params.tariffCommission)[sparte.toUpperCase()].type) }}</span>

                <EditField
                 v-if="readonly !== true"
                  :value="$filters.formatNumber((params.tariffCommission, null, NULLVALUE)[sparte.toUpperCase()].value)"
                  @change="setCommission(sparte,$filters.formatInput($event,'float'))"
                  clearable
                  :rules="[rules.required]"
                  :readonly="readonly"
                  :suffix="getSuffix((params.tariffCommission)[sparte.toUpperCase()].type)"
                  type="ufloat"
                />
              </td>
            </tr>

            </table>
          </v-col>
        </v-row>
        -->
      <!-- Provision Ende -->
      <v-row style="overflow-x: auto; overflow-y: hidden;">
        <v-col class="mb-16">

          <table class="text-center additionalProductTable v-data-table" cellspacing=0 cellpadding=0>
            <tr>
              <th class="text-center first firstRow px-4">{{ $t('condAdmin.additionalProduct.duration') }}</th>
              <!-- Tarifsparten -->
              <th class="firstRow px-4" v-for="(sparte) in params.tariffSparten" :key="sparte">
                <span>{{ sparte }}</span>
                <!--
                <EditField
                    v-if="readonly !== true"
                    @change="setSparte(sparte, $event)"
                    :value="sparte"
                    clearable
                    :readonly="readonly"
                  /> -->
              </th>
            </tr>
            <tr v-for="(duration, index) in Object.keys(params.tariffValues)" :key="duration">
              <!-- Lauftzeit -->
              <td class="px-4 first">
                <span><strong>{{ duration }}</strong></span>
                <!--
                  <EditField
                    v-if="readonly !== true"
                    @change="setTariffId(duration, $event)"
                    :value="duration"
                    clearable
                    :readonly="readonly"
                  />
                  -->
              </td>
              <!-- Faktoren -->
              <td class="px-4" v-for="(sparte) in params.tariffSparten" :key="sparte"
                :class="index === maxDuration ? 'lastRow' : ''" style="min-width: 150px;max-width: 300px;">
                <v-row>
                  <v-col>
                    <span v-if="readonly == true">{{
                      $filters.formatNumber((params.tariffValues[duration])[sparte.toUpperCase()], null, NULLVALUE) }}
                    </span>
                    <EditField v-if="readonly !== true"
                      @change="setFaktor(sparte, duration, $filters.formatInput($event, 'float'))"
                      :value="$filters.formatNumber((params.tariffValues[duration])[sparte.toUpperCase()], null, NULLVALUE)"
                      clearable :placeholder="$t('base.value')" :readonly="readonly"
                      :appendIcon="index !== maxDuration ? 'mdi-arrow-down' : null"
                      @appendClicked="copyTariffValue(sparte, (params.tariffValues[duration])[sparte.toUpperCase()], duration, index)"
                      :class="index !== maxDuration ? '' : 'mr-8'" />
                  </v-col>

                </v-row>

              </td>
            </tr>
          </table>
        </v-col>
      </v-row>

    </v-card-text>
    <!-- <HistoryDlg ref="historyDlg" /> -->
  </v-card>
</template>

<script lang="js">
import condAdminAdditionalProductMixin from '@/condAdmin/js/condAdminAdditionalProductMixin.js';
import EditField from '@/common/EditField';
// import ConditionVersionDetail from './ConditionVersionDetail';
// import HistoryDlg from './HistoryDlg';

export default {
  name: 'AdditionalProduct_duration_yareto',
  components: {
    EditField
  },

  mixins: [condAdminAdditionalProductMixin],
  props: {
    params: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      tmpParams: null,
      texts: {
        de: {
          contribution: 'Beitrag',
          commission: 'Provision',
          payment: 'zahlweise'
        }
      }
    };
  },
  watch: {
    params () {
      this.tmpParams = this.params;
    }
  },
  computed: {
    maxDuration () {
      return Object.keys(this.params.tariffValues).length - 1;
    }
  },
  mounted () {
    this.tmpParams = this.params;
    this.init();
  },
  methods: {
    init () {

    },
    setTariffName (index, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      this.tmpParams.tariffDescription[index].tariffName = value;
    },

    setFaktor (sparte, duration, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      (this.tmpParams.tariffValues[duration])[sparte.toUpperCase()] = value;
    },
    setCommission (sparte, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      (this.tmpParams.tariffCommission)[sparte.toUpperCase()].value = value;
    },
    setPaymentMode (sparte, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      (this.tmpParams.tariffCommission)[sparte.toUpperCase()].paymentMode = value;
    },
    copyTariffValue (sparte, value, duration, index) {
      if ((index + 1) < Object.keys(this.params.tariffValues).length - 1) { // do only if there is next duration
        (this.tmpParams.tariffValues[this.$filters.formatInput(duration, 'int') + 1])[sparte.toUpperCase()] = value;
        this.tmpParams.isDirty = true;
      }
    },
    getSuffix (valueType) {
      return valueType === 'percent' ? '%' : this.currency;
    },
    setTariffId (index, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      this.tmpParams.tariffDescription[index].tariffId = value;
    },

    copyTariff (ilIndex) {
      this.notImplemented('copyTariff');
      /*
      let sourceInterestLine = this.getInterestLineByIndex(ilIndex);
      let nl = JSON.parse(JSON.stringify(sourceInterestLine));
      nl.minCredit = sourceInterestLine.maxCredit + 0.01;
      nl.maxCredit = null;
      nl.index = this.interestLineCounter;
      this.interestLineCounter++;

      let attributes = JSON.parse(JSON.stringify(this.condition.attributes.filter((a) => a.ilIndex === ilIndex)));
      for (let att of attributes) {
        att.ilIndex = nl.index;
        att.minCredit = nl.minCredit;
        att.maxCredit = nl.maxCredit;
        this.condition.attributes.push(att);
      }

      this.condition.interestLines.push(nl);
      this.setDirty(); */
    },
    addTariff () {
      this.notImplemented('addTariff');
      /*
      this.createInterestLine(this.condition.calcType, this.condition.interestLines, this.interestLineCounter, null);
      this.interestLineCounter++;
      this.setDirty(); */
    },
    async removeTariff (ilIndex) {
      this.notImplemented('removeTariff');
      /*
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.conditionEditor.dlghDeleteInterestLine'), this.$t('condAdmin.conditionEditor.dlgtDeleteInterestLine'))) {
        let index = this.condition.interestLines.findIndex((i) => i.index === ilIndex);
        if (index > -1) {
          this.condition.interestLines.splice(index, 1);
        }
        this.condition.attributes = this.condition.attributes.filter((a) => a.ilIndex !== ilIndex);
      }
      this.setDirty(); */
    },
    end () {
    }
  }
};
</script>

<style scoped>
.additionalProductTable>>>.v-input__append-outer .v-icon {
  color: var(--v-secondary-base) !important;
}

.additionalProductTable td,
.additionalProductTable th,
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.additionalProductTable .borderBottom {
  border-right: 0px !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.additionalProductTable td.noBorder {
  border: 0px !important;
}

.additionalProductTable td.first,
.additionalProductTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  min-width: 120px !important;
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}
</style>
