/* <script> */
import { v4 as uuidv4 } from 'uuid';
import condAdminMixin from './condAdminMixin.js';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { formatLock } from './condAdminHelper.js';

export default {
  mixins: [condAdminMixin],
  data () {
    return {
      rules: {
        required: (value) => !!value || this.$t('base.required')
      }
    };
  },
  computed: {
  },
  methods: {
    async refreshAdditionalProductList () {
      this.$store.state.condAdmin.additionalProductList = [];
      this.$store.state.condAdmin.additionalProductLoading = true;
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey
      };

      let processData = await this.$restClient.callProcess('condAdmin', 'getAdditionalProductList', ioData);
      if (isProcessStateOK(processData)) {
        this.$store.state.condAdmin.additionalProductList = processData.ioData.additionalProducts;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      this.$store.state.condAdmin.additionalProductLoading = false;
    },
    // ------------------------------------------------------------------------
    // async readAdditionalProduct (uid, version, source, getVersions = false) {
    async readAdditionalProduct (addProductObjectKey, source, getVersions = false) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        additionalProductObjectKey: addProductObjectKey,
        includeVersions: getVersions
      };
      if (source != null) {
        ioData.additionalProductUid = source.uid;
        if (source.inherited === true) {
          ioData.clientKey = this.$store.state.condAdmin.orgSettings.parentKey;
        }
      } else {
        if (getVersions === true) {
          console.debug('!!! BAD PARAMETERS !!! getVersions === true and source === null');
        }
      }

      let processData = await this.$restClient.callProcess('condAdmin', 'readAdditionalProduct', ioData, true);
      if (isProcessStateOK(processData)) {
        if (source == null) {
          source = processData.ioData.additionalProduct;
          // prepareConditionList([source]);
        } else {
          Object.assign(source, processData.ioData.additionalProduct);
        }
        // condition.commissionParams = createcommissionParams(condition);
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return source;
    },

    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    async checkEditPreAdditionalProduct (mode, additionalProduct = null) {
      let newVersion = 1;
      let uid = null;
      let addProdList = this.$store.state.condAdmin.additionalProductList;

      if (mode === 'edit') {
        uid = additionalProduct.uid;

        if (additionalProduct.state !== 0 && additionalProduct.state !== 1) {
          // check if draft--version already exists
          let draft = addProdList.find((v) => v.uid === uid && v.state === 0);

          if (draft != null) {
            try {
              let replace = await this.$globals.Confirm.yesNoCancelDlg(
                this.$t('condAdmin.additionalProductEditor.dlghDraftExists'),
                this.$t('condAdmin.additionalProductEditor.dlgtDraftExists'),
                this.$t('base.replace'), this.$t('base.keep'));
              if (!replace) {
                additionalProduct = draft;
              }
              newVersion = draft.version;
            } catch (e) {
              return false;
            }
          } else {
            newVersion = 1;
            // find highest version
            for (let v of addProdList) {
              if (v.version > newVersion && v.uid === uid) newVersion = v.version;
            }
            newVersion++;// = newVersion + 1;
          }
        } else {
          newVersion = additionalProduct.version;
        }
      } else { // copy or new-mode
        uid = uuidv4();
      }

      // check and set editLock in BE
      let isLockSet = false;
      let lockIoData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        targetType: 'additionalProduct',
        targetIdentifier: uid,
        targetVersion: newVersion
      };

      let forceUpdateLock = false;
      let finishedLockCheck = false;
      let processName = 'createLock';
      while (!finishedLockCheck) {
        let processData = await this.$restClient.callProcess('condAdmin', processName, lockIoData, true);
        if (isProcessStateOK(processData)) {
          if (processData.processState.state === 'warning') {
            // additionalProduct already locked
            let lock = processData.ioData.lock;
            forceUpdateLock = await this.$globals.Confirm.yesNoDlg(
              this.$t('condAdmin.additionalProductEditor.dlghAddProductLocked'),
              this.$t('condAdmin.additionalProductEditor.dlgtAddProductLocked', formatLock(lock)),
              this.$t('base.overwrite'), this.$t('base.cancel'));
            if (forceUpdateLock) {
              processName = 'forceLock';
            } else {
              finishedLockCheck = true;
            }
          } else {
            this.$globals.Info.open(processData.processState.messages);
            finishedLockCheck = true;
            isLockSet = true;
          }
        } else {
          this.$globals.Info.open(processData.processState.messages);
          finishedLockCheck = true;
        }
      }

      if (isLockSet) {
        this.setWorkingAdditionalProduct(mode, uid, newVersion, additionalProduct);

        return true;
      }
      return false;
    },
    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    setWorkingAdditionalProduct (mode = 'new', uid, version = 1, source = null) {
      console.log('setWorkingAdditionalProduct', mode);

      let workingValidFrom = this.DateTimeHelper.NOW;
      let workingValidTo = this.DateTimeHelper.INFINITEDATETIME;

      if (mode === 'copy') {
        if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
        if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
      }
      if (mode === 'edit') {
        if (source.state === 0 || source.state === 1) {
          if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
          if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
        } else {
          if (source.validTo != null && this.DateTimeHelper.isLater(source.validTo /* than NOW */)) {
            if (this.DateTimeHelper.isEarlier(source.validTo, this.DateTimeHelper.INFINITEDATE)) {
              workingValidFrom = this.DateTimeHelper.formatSqlDateTimeAfter(source.validTo);
            } else if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) {
              workingValidFrom = source.validFrom;
            }
          }
        }
      }

      if (mode === 'edit') {
        if (source.dealerCommission === null) {
          source.dealerCommission = {};
        }
      }
      let wAP = {
        workingMode: mode,
        isDirty: false,
        clientKey: this.$store.state.condAdmin.clientKey,
        objectKey: uid + '_' + version,
        state: 0,
        dealerCommission: mode === 'new' ? {} : source.dealerCommission == null ? {} : JSON.parse(JSON.stringify(source.dealerCommission)),
        tariffCommission: mode === 'new' ? {} : JSON.parse(JSON.stringify(source.tariffCommission)),
        tariffInsuranceTax: mode === 'new' ? {} : JSON.parse(JSON.stringify(source.tariffInsuranceTax)),
        tariffDescription: mode === 'new' ? [] : JSON.parse(JSON.stringify(source.tariffDescription)),
        tariffName: mode === 'new' ? '' : source.tariffName,
        tariffValues: mode === 'new' ? {} : JSON.parse(JSON.stringify(source.tariffValues)),
        tariffValuesType: mode === 'new' ? null : source.tariffValuesType,
        tariffFEName: mode === 'new' ? '' : source.tariffFEName,
        tariffSparten: mode === 'new' ? '' : source.tariffSparten,
        uid: uid,
        validFrom: this.DateTimeHelper.formatSqlDateTime(workingValidFrom),
        validTo: this.DateTimeHelper.formatSqlDateTime(workingValidTo),
        version: version,
        versions: mode === 'edit' && source.versions != null ? JSON.parse(JSON.stringify(source.versions)) : []
      };

      this.$store.state.condAdmin.workingAdditionalProduct = wAP;
      console.log('workingAdditionalProduct was set');
      if (mode === 'edit' && source != null) source.outdated = true;
    },
    /* *******************
    *
    * DELETE
    *
    ******************* */
    async deleteAdditionalProduct (additionalProduct, skipDialog = false) {
      if (additionalProduct.state > 5 || additionalProduct.inherited === true) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.additionalProductEditor.infoCannotDeleteAddProduct') }]);
        return false;
      }
      if (skipDialog === true || await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.additionalProductEditor.dlghDeleteAddProduct'), this.$t('condAdmin.additionalProductEditor.dlgtDeleteAddProduct'))) {
        let ioData = {
          clientKey: this.$store.state.condAdmin.clientKey,
          objectKey: additionalProduct.objectKey,
          version: additionalProduct.version
        };
        let processData = await this.$restClient.callProcess('condAdmin', 'deleteAdditionalProduct', ioData, true);
        if (isProcessStateOK(processData)) {
          this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.additionalProductEditor.infoDeletedAddProduct') }]);
          this.refreshAdditionalProductList();
          return true;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      }
      return false;
    },
    /* *******************
    *
    * Save and check
    *
    ******************* */
    async saveAdditionalProduct (additionalProduct, checkCustomComponents = true) {
      if (additionalProduct.state !== 0 && additionalProduct.state !== 1) {
        return false;
      }

      // this.collectCustomComponentsData(additionalProduct);

      let messages = [];
      // check mandatory values

      // if condition.validTo is passed an error will be returned, because condition can't become active anymore
      if (additionalProduct.validTo != null && this.DateTimeHelper.isEarlier(additionalProduct.validTo /* than NOW */)) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') });
      }

      // validate custom components data
      if (checkCustomComponents === true) {
        this.validateCustomComponentsData(additionalProduct, messages);
      }

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return null;
      } else {
        try {
          // if condition.validFrom is passed it will be set to current date
          if (additionalProduct.validFrom == null || this.DateTimeHelper.isEarlier(additionalProduct.validFrom /* than NOW */)) {
            additionalProduct.validFrom = this.DateTimeHelper.NOW;
            this.$globals.Info.open([{ type: 'warning', message: this.$t('condAdmin.additionalProductEditor.infoValidFromChangedToToday') }]);
          }

          let additionalProduct2save = JSON.parse(JSON.stringify(additionalProduct));

          // clean Values
          delete additionalProduct2save.versions;
          delete additionalProduct2save.isDirty;
          delete additionalProduct2save.workingMode;

          // create ioData
          let ioData = {
            clientKey: this.$store.state.condAdmin.clientKey,
            additionalProduct: additionalProduct2save
          };

          // backend-request
          let processName = additionalProduct.workingMode === 'edit' ? 'updateAdditionalProduct' : 'createAdditionalProduct';
          console.debug('processName', processName);
          let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
          if (isProcessStateOK(processData)) {
            return processData;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        } catch (err) {
          console.error(err.message);
        }
        return null;
      }
    },
    // collectCustomComponentsData () {
    // },
    validateCustomComponentsData (additionalProduct, messages) {
      this.$refs.customAdditionalProduct.validateCustomParams(additionalProduct, messages);
    },
    async setCheckState (additionalProduct) {
      if (additionalProduct.isDirty && await this.saveAdditionalProduct(additionalProduct) !== true) {
        return false;
      }
      // if additionalProduct.validTo is passed an error will be returned, because additionalProduct can't become active anymore
      if (additionalProduct.validTo != null && this.DateTimeHelper.isEarlier(additionalProduct.validTo /* than NOW */)) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') }]);
      }
      // check plausibilities
      let beginDateCheck = await this.validateBeginDate(additionalProduct);
      let endDateCheck = await this.validateEndDate(additionalProduct);

      if (!beginDateCheck || !endDateCheck) {
        return false;
      }

      await this.setState(additionalProduct, 2);
      if (additionalProduct.state !== 0 && additionalProduct.state !== 1) {
        this.locked = false;
        this.$router.push('/condAdmin/additionalProduct/' + additionalProduct.objectKey);
      }
    },
    async setState (additionalProduct, state) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        additionalProductUid: additionalProduct.uid,
        additionalProductVersion: additionalProduct.version,
        state: state
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'updateAdditionalProductState', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.common.infoChangedState') }]);
        additionalProduct.state = processData.ioData.newState;
        let listedAdditionalProduct = this.$store.state.condAdmin.additionalProductList.find((v) => v.uid === additionalProduct.uid && v.version === additionalProduct.version);
        if (listedAdditionalProduct != null) {
          listedAdditionalProduct.state = processData.ioData.newState;
        }

        return true;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return false;
    }
  }
};
// </script>
